import _ from 'lodash';
import { call, put } from 'redux-saga/effects';
import { FIELDS_TO_MASK, SYSTEM_SERVICE_URL } from '../../constants';
import { setErrorPage } from '../../store/errorPage/errorPage.slice';
import { maskFields } from '../../utils';
import { CLIENT_CLOUD_LOGS_MUTATION, USER_INFORMATION_QUERY } from './api-queries';
import { fetchFromSalesApi } from './sales-api';
import { extractData } from './sales-api-functions';
const STORAGE_USER_INFORMATION = 'sdr/user-information';
const createUserInformationQuery = (applicationName, useCache, onError) => {
    const cacheSettings = useCache ? { cacheKey: STORAGE_USER_INFORMATION } : {};
    return {
        url: SYSTEM_SERVICE_URL,
        query: USER_INFORMATION_QUERY,
        ...cacheSettings,
        onError,
        options: {
            variables: {
                applicationName,
            },
        },
    };
};
const convertHostDetails = (rawUserInformation) => {
    const hostDetails = { opCodes: {} };
    for (const opcodeByHost of rawUserInformation.host || []) {
        hostDetails.opCodes[opcodeByHost.hostName] = opcodeByHost.opcode;
    }
    return hostDetails;
};
const convertHostPermissions = (rawUserInformation) => {
    var _a;
    return (_a = (rawUserInformation.permissions.host || [])) === null || _a === void 0 ? void 0 : _a.reduce((acc, p) => {
        acc[p.hostName] = p.permissions;
        return acc;
    }, {});
};
const convertFromGraphQL = (rawUserInformation) => {
    var _a, _b;
    const result = {
        ..._.omit(rawUserInformation, 'host'),
        permissions: {
            ..._.omit(rawUserInformation.permissions, 'host'),
        },
    };
    if ((((_a = rawUserInformation.permissions.host) === null || _a === void 0 ? void 0 : _a.length) || 0) > 0) {
        result.permissions.host = convertHostPermissions(rawUserInformation);
    }
    if ((((_b = rawUserInformation.host) === null || _b === void 0 ? void 0 : _b.length) || 0) > 0) {
        result.host = convertHostDetails(rawUserInformation);
    }
    return result;
};
function* fetchUserInformation(applicationName, fromCache, onError) {
    const fetchArgs = createUserInformationQuery(applicationName, fromCache, onError);
    const response = yield call(fetchFromSalesApi, fetchArgs);
    if (response.errors) {
        yield put(setErrorPage(response.errors));
    }
    return extractData(fetchArgs.query, response);
}
export function* getUserInformation(applicationName, fromCache, onError) {
    const rawUserInformation = yield* fetchUserInformation(applicationName, fromCache, onError);
    return convertFromGraphQL(rawUserInformation);
}
export function* logToCloud(args) {
    try {
        const { data } = args;
        yield call(fetchFromSalesApi, {
            url: SYSTEM_SERVICE_URL,
            query: CLIENT_CLOUD_LOGS_MUTATION,
            options: {
                variables: {
                    ...args,
                    data: maskFields(data, FIELDS_TO_MASK),
                },
            },
        });
    }
    catch (_a) {
        console.error('unable to call clientLogs with ', args);
    }
}
